import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DeterminList } from "../components/DeterminList";
import { getDeterminList } from "../modules/determins";
import { useAuth } from "../contexts/auth/auth.context";

export const DeterminListContainer = ({brandParam, nationParam, platformParam, searchTypeParam, searchTextParam, sortParam, pageParam}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ brand, setBrand ] = useState(brandParam);
  const [ nation, setNation ] = useState(nationParam);
  const [ platform, setPlatform ] = useState(platformParam);
  const [ searchType, setSearchType ] = useState(searchTypeParam);
  const [ searchText, setSearchText ] = useState(searchTextParam);
  const [ sort, setSort ] = useState(sortParam);
  const [ page, setPage ] = useState(pageParam);
  const BODY_COUNT_PER_PAGE = 24;

  const { data, loading, error} = useSelector((state) => state.determinReducer.determinlist);
  const dispatch = useDispatch();

  // 브랜드
  const onBrandSubmit = (brandParam) => {
    setPage(0);
    setBrand(brandParam);
  }

  // 국가
  const onNationSubmit = (nationParam, platformParam) => {
    setPage(0);
    setNation(nationParam);
    setPlatform(platformParam);
  }

  // 플랫폼
  const onPlatformSubmit = (platformParam) => {
    setPage(0);
    setPlatform(platformParam);
  }

  // 검색어
  const onSearchTextSubmit = (searchTypeParam, searchTextParam) => {
    setPage(0);
    setSearchType(searchTypeParam);
    setSearchText(searchTextParam);
  }

  // 정렬순
  const onSortSubmit = (sortParam) => {
    setPage(0);
    setSort(sortParam);
  }

  // 페이지
  const onPageSubmit = (pageParam) => {
    setPage(pageParam);
  }


  // 컴포넌트 마운트 후 목록 요청
  //       { script: { script: doc['determin_price'].value < doc['price_floor'].value}}      
  useEffect(() => {
    let params = { 
      track_total_hits: true,
      size: 24, 
      sort: [ 
      ],      
      query : { 
        bool: { 
          filter: 
          [ 
            { script: { script: "doc['determin_price'].value < doc['price_floor'].value"} }
          ]
        }
      }
    };
    if (sort === "created_desc") {
      params.sort.push(
        { "created_at": { "order": "desc", "format": "strict_date_optional_time", "unmapped_type": "boolean" } }, 
        { "_doc": { "order": "desc", "unmapped_type": "boolean" } } 
        // {"created_at": {"order" : "desc", "format": "strict_date_optional_time", "unmapped_type": "boolean" } }
      )
    }
    else if (sort === "created_asc") {
      params.sort.push(
        {"created_at": {"order" : "asc", "format": "strict_date_optional_time", "unmapped_type": "boolean" } },
        { "_doc": { "order": "asc", "unmapped_type": "boolean" } } 
      )
    }
    else if (sort === "price_desc") {
      params.sort.push(
        {"determin_price": {"order" : "desc", "numeric_type" : "double" } }
      )
    }
    else if (sort === "price_asc") {
      params.sort.push(
        {"determin_price": {"order" : "asc", "numeric_type" : "double" } }
      )
    }

    if (brand.length > 0) {
      params.query.bool.filter.push({ match: { brand: `${brand}` }})
    }
    if (nation.length > 0) {
      params.query.bool.filter.push({ match: { nation: `${nation}` }})
    }
    if (platform.length > 0) {
      params.query.bool.filter.push({ match: { mall: `${platform}` }})
    }
    if (searchType === "seller" && searchText.length > 0) {
        params.query.bool.filter.push({ match: { seller: `${searchText}` }})
    }
    else if (searchType === "determin_name" && searchText.length > 0) {
      params.query.bool.filter.push({ match: { determin_name: `${searchText}` }})
    }
    else if (searchText.length > 0) {
      params.query.bool.filter.push({ multi_match: { fields: ["seller", "determin_name"], query: `${searchText}` }})
    }
    params["from"] = page < 0 ? 0 : page * BODY_COUNT_PER_PAGE;

    // console.log(JSON.stringify(params));
    dispatch(getDeterminList(params));
    if (page < 0) setPage(0);
  }, [dispatch, brand, nation, platform, searchType, searchText, sort, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <DeterminList 
    dataParam={data}
    brandParam = {brand}
    nationParam = {nation}
    platformParam = {platform}
    searchTypeParam = {searchType}
    searchTextParam = {searchText}
    sortParam = {sort}
    pageParam = {page}
    onPageSubmit={onPageSubmit} 
    onBrandSubmit={onBrandSubmit} 
    onNationSubmit={onNationSubmit} 
    onPlatformSubmit={onPlatformSubmit} 
    onSearchTextSubmit={onSearchTextSubmit}
    onSortSubmit={onSortSubmit}
    />
  );
}

