import axios from "./index";

class ConfirmedApi {
  // 수집품 확인
  static get_confirm_count = (formData) => {
    let data = {}
    data['params'] = formData
    const response = axios.get(`/get_confirm_count/`, data);
    return response; 
  };
  
}
export default ConfirmedApi;