import Axios from "axios";
import { BACKEND_SERVER_BASEURL } from "../config/constant";
import { useAuth } from "../contexts/auth/auth.context";

const axios = Axios.create({
  baseURL: `${BACKEND_SERVER_BASEURL}/api/`,
  headers: { 
    "Content-Type": "application/json", 
  },
});

// Track if token refresh is in progress
let isRefreshing = false;
// Store pending requests that should be retried after token refresh
let pendingRequests = [];


axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;    
  },
  (error) => Promise.reject(error)
);

// Add response interceptor to handle 401 errors
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If error is 401 and we haven't tried to refresh the token yet
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      
      // If refresh is already in progress, add this request to pending requests
      if (isRefreshing) {
        handleLogout();
        return new Promise((resolve, reject) => {
          pendingRequests.push({
            resolve,
            reject,
            request: originalRequest
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        // Call the Django token refresh endpoint
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
          // If no refresh token exists, logout user
          handleLogout();
          return Promise.reject(error);
        }

        const response = await axios.post('/api/token/refresh/', {
          refresh: refreshToken
        });

        // If token refresh successful, update tokens and retry failed requests
        if (response.status === 200) {
          const { access } = response.data;
          localStorage.setItem('access_token', access);
          
          // Update auth header for original request
          originalRequest.headers['Authorization'] = `Bearer ${access}`;
          
          // Retry all pending requests with new token
          pendingRequests.forEach(request => {
            request.resolve(axios(request.request));
          });
          pendingRequests = [];
          
          // Return the original request with new token
          return axios(originalRequest);
        }
      } catch (refreshError) {
        // If refresh token fails, reject all pending requests and logout
        pendingRequests.forEach(request => {
          request.reject(refreshError);
        });
        pendingRequests = [];
        
        // Clear tokens and redirect to login
        handleLogout();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    // Return the original error if it's not a 401 or we've already tried refreshing
    return Promise.reject(error);
  }
);

const handleLogout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  window.location.href = '/login';
};


export default axios;

