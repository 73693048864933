import PropTypes from "prop-types";
import React, {useContext} from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth/auth.context";
import AuthApi from "../../api/auth";
import AlertContext from '../../contexts/alert/AlertContext';

export const RightMenu = ({ className, afterClassName, afterClassNameOverride, divClassName, href }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();
  const { alert: alertComp } = useContext(AlertContext);

  // 로그인
  const onLogin = () => {
    navigate("/login");
  };

  // 회원가입
  const onSignup = () => {
    navigate("/signupagree");
  };

  // 로그아웃
  const onLogout = () => {
    AuthApi.Logout(user);
    setUser(null);
    alert("로그아웃 되었습니다. 감사합니다.");
    navigate("/login");
  };

  // 마이페이지
  const handleChangePassword = () => {
    navigate("/changepwd");
  };

  // 고객센터
  const onCustomerCenter = () => {
    navigate("/bulletinlist");
  };

  return (
    <div>
    { user && user.access && user.access.length > 200 ? (

    <div className={`right-menu ${className}`}>
      <div className="item-link" style={{cursor:'pointer'}}>
        <div className="text-wrapper-3" onClick={onLogout} >로그아웃</div>

        <div className="after" />
      </div>

      <div className="item-link-2" style={{cursor:'pointer'}}>
        <div className="text-wrapper-4" onClick={handleChangePassword}>비번변경</div>

        <div className="after" />
      </div>

      {/* <div className="div-wrapper" style={{cursor:'pointer'}}>
        <div className="text-wrapper-5" onClick={onCustomerCenter}>고객센터</div>
      </div> */}
      <div className="div-wrapper">
        <div className="text-wrapper-5" >고객센터</div>
      </div>

    </div>

    ) : (

    <div className={`right-menu ${className}`}>
      <div className="item-link" style={{cursor:'pointer'}}>
        <div className="text-wrapper-3" onClick={onLogin}>로그인</div>

        <div className="after" />
      </div>

      <div className="item-link-2" style={{cursor:'pointer'}}>
        <div className="text-wrapper-4" onClick={onSignup}>회원가입</div>

        <div className="after" />
      </div>

      <div className="div-wrapper" style={{cursor:'pointer'}}>
        <div className="text-wrapper-5" onClick={onCustomerCenter}>고객센터</div>
      </div>
    </div>

    )}
    </div>

  );
};
