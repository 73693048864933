import axios from "./index";

class AuthApi {
  // 로그인 인증
  static Login = (data) => {
    return axios.post(`${base}/login`, data);
  };

  // 비밀번호
  static Password = (data) => {
    return axios.post(`${base}/password`, data);
  };

  // 비밀번호변경
  static ChangePassword = (data) => {
    return axios.post(`${base}/changepwd`, data);
  };

  // 이메일 인증번호 전송
  static SendEmailAuth = (data) => {
    return axios.post(`${base}/auth_code`, data);
  };

  // 회원 등록
  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };

  // 로그아웃
  static Logout = (user) => {
    // let data = {
    //   refresh: user.refresh,
    // };
    // return axios.post(`${base}/logout`, data, {
    //   headers: {
    //     Authorization: `Bearer ${user.access}`,
    //     Cookie: `refresh=${user.refresh};`,
    //   },
    // });
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    window.location.href = '/login';
  };
}
let base = "users";

export default AuthApi;
